import React, {useEffect, useState} from "react";
import axios from "axios";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useSelector} from "react-redux";

const Plusvalue = () => {

    const {user: currentUser} = useSelector((state) => state.user);

    const [project, setProject] = useState({});
    const [user, setUser] = useState({});

    const getData = async () => {
        await axios.get(process.env.REACT_APP_TERRA_URL + 'api/stats/project_plusvalue/1')
            .then(response => {
                setProject(response.data);
            })
            .catch(error => console.log(error));

        await axios.get(process.env.REACT_APP_TERRA_URL + 'api/stats/user_plusvalue/'+ currentUser.id +'/1')
            .then(response => {
                setUser(response.data);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Plusvalue (Project Sumak)</h4>
                    <Row>
                        <Col className="text-center" md={6}>
                            <h1 className="text-primary">{project.dolars} USD</h1>
                            <h5 className="text-secondary">DOLARS</h5>
                        </Col>
                        <Col className="text-primary text-center" md={6}>
                            <h1 className="text-primary">{project.percent} %</h1>
                            <h5 className="text-secondary">PERCENT</h5>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">My Plusvalue (Project Sumak)</h4>
                    <Row>
                        <Col className="text-center" md={6}>
                            <h1 className="text-primary">{ user.plusvalue ? user.plusvalue.dolars : 0 } USD</h1>
                            <h5 className="text-secondary">DOLARS</h5>
                        </Col>
                        <Col className="text-primary text-center" md={6}>
                            <h1 className="text-primary">{ Math.round(user.plusvalue ? user.plusvalue.percent : 0, 2) } %</h1>
                            <h5 className="text-secondary">PERCENT</h5>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">My Capital Units (Project Sumak)</h4>
                    <Row>
                        <Col className="text-center" md={6}>
                            <h1 className="text-primary">{ user.blocks }</h1>
                            <h5 className="text-secondary">BLOCKS</h5>
                        </Col>
                        <Col className="text-primary text-center" md={6}>
                            <h1 className="text-primary">{ user.capital_units }</h1>
                            <h5 className="text-secondary">CAPITAL UNITS</h5>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default Plusvalue;
