import React from "react";
import {Row} from "reactstrap";

const Preloader = () =>
    <Row className="text-center align-items-center justify-content-center">
        <div className="spinner">
            <i className="ri-loader-line spin-icon"/>
        </div>
    </Row>

export default Preloader;
