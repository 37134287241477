import React, {useEffect, useState} from "react";
import {Switch, BrowserRouter as Router, withRouter} from "react-router-dom";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import 'toastr/build/toastr.min.css'

// Import scss
import "./assets/scss/theme.scss";
import {useDispatch, useSelector} from "react-redux";
import {checkAuth} from "./features/userSlice";
import {ToastContainer} from "react-toastify";

const App = (props) => {

	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(checkAuth())
			.then(response => {
				setLoading(false)
			})
			.catch(error => {
				props.history.push('/login')
				console.log(error)
			})
	}, [])

	return (
		<>
			<Switch>
				{publicRoutes.map((route, idx) => (
					<AppRoute
						path={route.path}
						layout={NonAuthLayout}
						component={route.component}
						key={idx}
						isAuthProtected={false}
					/>
				))}

				{
					loading ?
						<div id="preloader">
							<div id="status">
								<div className="spinner">
									<i className="ri-loader-line spin-icon"/>
								</div>
							</div>
						</div> :
						authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={VerticalLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))
				}
			</Switch>
			<ToastContainer />
		</>
	)
}

export default withRouter(App);
