import React, {useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import BlockDetails from "./BlockDetails";
import UploadDoc from "./UploadDoc";

const Block = ({withOptions, block, getBlocks, ...props}) => {

    // Modals Controls
    const [details, setDetails] = useState(false)
    const [documents, setDocuments] = useState(false)

    const toggleDetails = () => setDetails(!details)
    const toggleDocuments = () => setDocuments(!documents)

    /**
     * @desc Method to calculate the current value of the block.
     * @returns {number}
     */
    const currentValue = () => {
        return (block.capital_units * block.project.current.capital_unit_value);
    }

    /**
     * @desc Method to calculate the capital gain of the block.
     * @returns {number}
     */
    const capitalGain = () => {
        return (((currentValue() - block.initial_value) * 100) / block.initial_value)
    }

    return (
        <>
            <Card className="text-white-50 block shadow shadow-lg">
                <CardBody>
                    <Row style={{justifyContent: "center", alignItems: "center"}}>
                        <Col>
                            <h4 className="text-white">BLOCK ID: {block.id}</h4>
                        </Col>
                        <Col>
                            <h4 style={{textAlign: "right"}} className="text-white">
                            <span className="p-2 bg-warning badge badge-warning">
                                {block.status}
                            </span>
                            </h4>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="justify-content-sm-center">
                        <Col md={5} className="block-data">
                            <Row>
                                <Col xs={7} md={12}>
                                    <small>Proyecto:</small>
                                    <p>{block.project.name}</p>
                                </Col>
                                <Col xs={5} md={12}>
                                    <small>Capital Units:</small>
                                    <p>{block.capital_units}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className="block-data">
                            <Row>
                                <Col xs={6} md={12}>
                                    <small>Initial Value:</small>
                                    <p>$ {block.initial_value} USD</p>
                                </Col>
                                <Col xs={6} md={12}>
                                    <small>Current Value:</small>
                                    <p>$ {currentValue()} USD</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={3} className="mt-sm-4 mt-md-0 block-gain">
                            <div className="">
                                <h5>Capital Gain:</h5>
                                <h1>{capitalGain()}%</h1>
                            </div>
                        </Col>
                    </Row>
                    {
                        withOptions ? <>
                            <hr/>
                            <Row className="text-center text-white">
                                {/*TODO: Uncomment when discharge feature is completed*/}
                                {/*<Col xs={4} md={3}>*/}
                                {/*    <i className="fas fa-2x fa-chevron-down"/>*/}
                                {/*    <p>Discharge</p>*/}
                                {/*</Col>*/}

                                <Col xs={4} md={3} onClick={toggleDocuments}>
                                    <i className="fas fa-2x fa-upload"/>
                                    <p>Upload Doc.</p>
                                </Col>

                                {/*TODO: Uncomment when discharge feature is completed*/}
                                {/*<Col xs={4} md={3}>*/}
                                {/*    <i className="fas fa-2x fa-exchange-alt"/>*/}
                                {/*    <p>Transfer</p>*/}
                                {/*</Col>*/}

                                <Col xs={4} md={3} onClick={toggleDetails}>
                                    <i className="fas fa-2x fa-eye"/>
                                    <p>Details</p>
                                </Col>
                            </Row>
                        </> : <></>
                    }
                </CardBody>
            </Card>

            {
                withOptions ?
                    <>
                        <BlockDetails block={block} show={details} toggle={toggleDetails}/>
                        <UploadDoc getBlocks={getBlocks} block={block} show={documents} toggle={toggleDocuments}/>
                    </>
                    : null
            }
        </>
    )
}

export default Block;
