import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Pages
import Dashboard from "../pages/Dashboard/index";
import Projects from "../pages/Projects";
import MyBlocks from "../pages/MyBlocks";
import Reports from "../pages/Reports";

import Project from "../pages/Project";

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/projects", component: Projects },
	{ path: "/project/:id", component: Project, name: 'Project' },
	{ path: "/my-blocks", component: MyBlocks },
	{ path: "/reports", component: Reports},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
