import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";

const PaymentInfo = ({show, toggle, block, ...props}) => {
    return (
        <Modal size="lg" isOpen={show} toggle={toggle}>
            <ModalHeader toggle={toggle}/>
            <ModalBody>
                {
                    props.purchase ?
                        <Row className="text-center">
                            <h2 className="text-primary">
                                { props.t('Excellent, the Capital Units were booked.') }
                            </h2>
                            <h4 className="text-secondary">
                                { props.t('Just one more step to make them yours') }
                            </h4>
                        </Row> : <></>
                }
                <Row className="mt-4">
                    <Col md={12} className="mb-md-0" style={{marginBottom: "20px"}}>
                        <h3 className="text-secondary text-center">{ props.t('Payment Info') }</h3>
                        <p className="text-center">
                            { props.t('Payment must be made by bank transfer or consignment. You have 24 hours to make the transaction or you will lose the reservation of the Capital Units.') }
                        </p>
                    </Col>
                    <Col className="mt-3" md={6} style={{textAlign: "center"}}>
                        <h2>$ { block.current_value } USD</h2>
                        <h5 className="text-primary">{ props.t('Payment for {{ number }} Capital Units', { number: block.capital_units }) }</h5>
                    </Col>
                    <Col md={6} className="mt-3" style={{textAlign: "center"}}>
                        <h2 className="text-primary">361646441</h2>
                        <h5 className="text-secondary">{ props.t('BBVA Savings Account') }</h5>
                    </Col>
                    <Col md={12} className="mt-3" style={{textAlign: "center"}}>
                        <h5 className="text-secondary">{ props.t('NIT') }</h5>
                        <h2 className="text-primary">901478518-3</h2>
                    </Col>
                    <Col md={12} className="mt-4">
                        <h3 className="text-secondary text-center">{ props.t('Contract') }</h3>
                        <p className="text-center">
                            {props.t('You must download and fill out the following contract and then upload it so we can validate it.')}
                        </p>
                        <a href={block.project.contract} target="_blank" className="btn btn-block btn-primary">{ props.t('Download Contract') }</a>
                    </Col>
                    <Col md={12} className="mt-4">
                        <p className="text-center">
                            { props.t('Once the payment has been made, you must attach the contract and proof of the transaction and in a maximum of 72 hours our team will change the status of the capital units. You can upload the documents in My Blocks section.') }
                        </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => props.history.push('/projects')}
                >
                    { props.t('Close') }
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withRouter(withNamespaces()(PaymentInfo));
