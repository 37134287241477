import React, {useRef} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {withNamespaces} from "react-i18next";

const Terms = ({ show, toggle, terms, accept, ...props }) => {

    const acceptTerms = async () => {
        await accept();
    }

    return (
        <Modal className="modal-dialog-scrollable" size="lg" isOpen={show} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                { props.t('Terms & Conditions') }
            </ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={{__html: terms}}/>

                <Row className="justify-content-end">
                    <Col md={12}>
                        <a onClick={acceptTerms} className="btn btn-primary btn-block mb-2">{ props.t("I accept the Terms and Conditions") }</a>
                    </Col>
                    <Col md={12}>
                        <a onClick={toggle} className="btn btn-outline-secondary btn-block">{ props.t('Cancel') }</a>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
    )
}

export default withNamespaces()(Terms);
