import React, {Component, useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody, CardTitle, CardText, CardHeader, Badge, Tooltip} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Block from "./Components/Block";
import {useSelector} from "react-redux";
import axios from "axios";
import Preloader from "../../components/Preloader";

const MyBlocks = (props) => {

    // Auth User Data.
    const {user: currentUser} = useSelector((state) => state.user);

    // Blocks Array
    const [blocks, setBlocks] = useState([]);

    // Loading Cotnrol
    const [loading, setLoading] = useState(true);

    // Breadcrumb
    const breadcrumbItems = [
        {title: "Real Estate", link: "/"},
        {title: "My Blocks", link: "#"},
    ]

    /**
     * @desc Método para la obtención de los bloques.
     * @returns {Promise<void>}
     */
    const getBlocks = async () => {
        await setLoading(true);
        await axios.get(process.env.REACT_APP_TERRA_URL + 'api/blocks/' + currentUser.id)
            .then(response => {
                setBlocks(response.data.blocks)
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getBlocks().then(() => setLoading(false))
    }, [])

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="My Blocks" breadcrumbItems={breadcrumbItems}/>
                    {
                        loading ?
                            <Preloader/> :
                            <Row>
                                {
                                    blocks.map(block =>
                                        <Col md={6}>
                                            <Block getBlocks={getBlocks} block={block} withOptions/>
                                        </Col>)
                                }
                            </Row>
                    }
                </Container>
            </div>
        </>
    )
}

export default MyBlocks;
