import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import AuthService from "../services/auth.service";

export const checkAuth = createAsyncThunk(
    "auth/check",
    async (thunkAPI) => {
        try {
            if (localStorage.getItem('auth_token')) {
                const data = await AuthService.getUser();

                if (data === 'UNAUTHENTICATED') {
                    thunkAPI.rejectWithValue();
                }

                return {
                    user: data,
                    isLoggedIn: true
                }
            }
            else {
                return {
                    user: null,
                    isLoggedIn: false
                }
            }
        } catch (error) {
            thunkAPI.rejectWithValue();
        }
    }
)

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(username, password);

            if (data.error) {
                return thunkAPI.rejectWithValue(data)
            }

            return { user: data };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async () => {
        try {
            const data = await AuthService.logout();
            return { user: null };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return message;
        }
    }
)


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        isLoggedIn: localStorage.getItem('token')
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [checkAuth.fulfilled]: (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.user = action.payload.user;
        },
        [checkAuth.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        }

    },
})

const { reducer } = userSlice;
export default reducer;
