import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = (props) => {

    const [isMobile, setIsMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        let currentage = capitalizeFirstLetter(props.location.pathname);
        currentage = currentage.replaceAll("-", " ");
        document.title = currentage + " | Real Estate";
    }, [])

    const toggleSidebar = () => {
        if (!document.body.classList.contains('sidebar-enable'))
        {
            if(document.body) document.body.classList.add('sidebar-enable');
            if(document.body) document.body.classList.add('vertical-collpsed');
        } else {
            if(document.body) document.body.classList.remove('sidebar-enable');
            if(document.body) document.body.classList.remove('vertical-collpsed');
        }
    }

    return (
        <>
            {
                <div id="layout-wrapper">
                    <Header toggleMenuCallback={toggleSidebar}/>
                    <Sidebar
                        theme="dark"
                        type="default"
                        isMobile={isMobile}
                    />
                    <div className="main-content">
                        {props.children}
                        <Footer/>
                    </div>
                </div>
            }
        </>
    )
}
export default withRouter(Layout);

