import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { CircleSlider } from "react-circle-slider";
import Terms from "./Terms";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";

const Purchase = ({ open, toggle, togglePayment, project, ...props }) => {
  const { user: currentUser } = useSelector((state) => state.user);

  const [capitalUnits, setCapitalUnits] = useState(1);
  const handleCapitalUnits = (value) => setCapitalUnits(value);

  const [showTerms, setShowTerms] = useState(false);
  const toggleTerms = () => setShowTerms(!showTerms);

  const purchase = () => {
    axios
      .post(process.env.REACT_APP_TERRA_URL + "api/blocks", {
        user_id: currentUser.id,
        project_id: project.id,
        capital_units: capitalUnits,
        initial_value: capitalUnits * project.current.capital_unit_value,
        current_value: capitalUnits * project.current.capital_unit_value,
      })
      .then(async (response) => {
        await props.setBlock(response.data.block);
        await togglePayment();
        await toggleTerms();
        await toggle();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (project.id === 1) {
      setCapitalUnits(5);
    }
  }, [project]);

  return (
    <Modal size="lg" isOpen={open} toggle={toggle}>
      {project.current ? (
        <>
          <ModalHeader toggle={toggle}>
            {props.t("Purchase Capital Units")}
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center align-items-center">
              <Col md={12}>
                <p className="mb-4" style={{ textAlign: "center" }}>
                  {props.t(
                    "Ready to join this great project. Select the capital units you wish to purchase."
                  )}
                </p>
              </Col>
              <Col md={4} className="mx-4 text-center">
                <CircleSlider
                  size={240}
                  showTooltip={true}
                  knobRadius={15}
                  progressWidth={10}
                  circleWidth={25}
                  progressColor="#02CFB0"
                  value={capitalUnits}
                  min={project.id === 1000 ? 5 : 1}
                  max={
                    project.current
                      ? project.current.available_capital_units
                      : 1000
                  }
                  onChange={handleCapitalUnits}
                  stepSize={project.id === 1000 ? 5 : 1}
                />
              </Col>
              <Col
                md={1}
                className="mx-4 text-center my-md-0"
                style={{ margin: "20px 0px" }}
              >
                <h2>OR</h2>
              </Col>
              <Col md={4} className="mx-4">
                <FormGroup>
                  <Label for="exampleEmail">
                    {props.t("Number of Capital Units")}
                  </Label>
                  <Input
                    value={capitalUnits}
                    min={project.id === 1000 ? 5 : 1}
                    max={
                      project.current
                        ? project.current.available_capital_units
                        : 1000
                    }
                    disabled={
                      project.current.available_capital_units === 0 ||
                      project.id === 1
                    }
                    type="number"
                    onChange={(event) => handleCapitalUnits(event.target.value)}
                  />
                  {project.current.available_capital_units === 0 ? (
                    <FormText color="danger">
                      En estos momentos no hay unidades disponibles.
                    </FormText>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </Col>
              <Col className="mt-5" md={12}>
                <Row>
                  <Col
                    md={12}
                    className="mb-md-0"
                    style={{ marginBottom: "20px" }}
                  >
                    <h3 className="text-secondary text-center">
                      {props.t("Remember")}
                    </h3>
                  </Col>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <h2>
                      {project.current
                        ? project.current.available_capital_units
                        : ""}{" "}
                      /{" "}
                      <small className="text-secondary">
                        {project.capital_units}
                      </small>
                    </h2>
                    <h5 className="text-primary">
                      {props.t("Available Capital Units")}
                    </h5>
                  </Col>
                  <Col
                    md={6}
                    className="my-md-0"
                    style={{ textAlign: "center", marginTop: "30px" }}
                  >
                    <h2 className="text-primary">
                      ${" "}
                      {project.current
                        ? project.current.capital_unit_value
                        : ""}{" "}
                      <small>USD</small>
                    </h2>
                    <h5 className="text-secondary">
                      {props.t("Per Capital Unit")}
                    </h5>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <p className="mt-4" style={{ textAlign: "center" }}>
                  {props.t(
                    "Payment must be made by bank transfer or bank consignment. Once the payment is done you must upload the receipt along with the contract duly completed."
                  )}
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle}>{props.t("Cancel")}</Button>{" "}
            <Button
              color="primary"
              onClick={toggleTerms}
              disabled={project.current.available_capital_units === 0}
            >
              {props.t("Buy")}
            </Button>
          </ModalFooter>

          <Terms
            terms={project.terms ?? ""}
            show={showTerms}
            toggle={toggleTerms}
            accept={purchase}
          />
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default withNamespaces()(Purchase);
