import React, { Component } from "react";
import {Container, Row, Col, Card, CardTitle, CardBody, Button} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Real Estate", link : "/" },
                { title : "Reports", link : "#" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Reports" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardBody className="text-center">
                                        <CardTitle>
                                            <h3 className="text-primary">Avance de Obra</h3>
                                        </CardTitle>
                                        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
                                            <i style={{ fontSize: "70px" }} className="fa fa-file fa-3x text-primary"/>
                                        </Row>
                                        <a href="/files/terra_report.pdf" target="_blank" className="btn btn-primary"><i className="fa fa-eye"/> Ver Reporte</a>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Reports;
