import React, {useState} from "react";
import {Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import Block from "./Block";
import moment from "moment";

import img5 from "../../../assets/images/authentication-bg.jpg";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import PaymentInfo from "../../Project/Components/PaymentInfo";

const BlockDetails = ({show, toggle, block, ...props}) => {

    const [showPayemnt, setShowPayment] = useState(false)
    const toggleShowPayment = () => setShowPayment(!showPayemnt)

    return (
        <Modal size="xl" isOpen={show} toggle={toggle}>
            <ModalHeader toggle={toggle}/>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <h2 className="text-primary">{ props.t('Block Details') }</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={6}>
                        <h5 style={{fontWeight: 'lighter'}}><span className="text-primary">{ props.t('Buyed At') }:</span> { moment(block.created_at).fromNow() }
                        </h5>
                    </Col>
                    <Col xs={6}>
                        <h5 style={{fontWeight: 'lighter', textAlign: 'right'}}><span
                            className="text-primary">Generation:</span> 1</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Block block={block}/>
                    </Col>
                    <Col md={12} style={{ textAlign: 'right' }}>
                        <a onClick={toggleShowPayment} href="#" style={{ borderRadius: '30px' }} className="px-5 btn btn-outline-primary"> See Payment Data</a>
                        <a href={block.project.contract} target="_blank" style={{ borderRadius: '30px' }} className="px-5 btn btn-primary"><i className="fa fa-download"/> Download Contract</a>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Card className="shadow-none">
                            <CardBody>
                                <Row>
                                    <Col md={5} className="d-flex align-items-center">
                                        <img src={ block.project.images[0].path } width="100%"/>
                                    </Col>
                                    <Col md={7} className="d-flex align-items-center">
                                        <div style={{width: '100%'}}>
                                            <div className="mt-md-0 text-md-start"
                                                 style={{marginTop: "20px", textAlign: 'center'}}>
                                                <h5 className="text-secondary">{ block.project.ubication.city + ', ' + block.project.ubication.state}</h5>
                                                <h4 className="text-primary">{ block.project.name }</h4>
                                            </div>
                                            <Row className="my-4">
                                                <Col md={6} style={{textAlign: "center"}}>
                                                    <h1>{ block.project.current.available_capital_units } / <small className="text-secondary">{ block.project.capital_units }</small></h1>
                                                    <h4 className="text-primary">{ props.t('Available Capital Units') }</h4>
                                                </Col>
                                                <Col md={6} className="my-md-0"
                                                     style={{textAlign: "center", marginTop: "30px"}}>
                                                    <h1 className="text-primary">$ { block.project.current.capital_unit_value } <small>USD</small></h1>
                                                    <h4 className="text-secondary">{ props.t('Per Capital Unit') }</h4>
                                                </Col>
                                            </Row>
                                            <a onClick={() => props.history.push('/project/' + block.project.id)} className="btn btn-block btn-primary">
                                                <i className="fa fa-eye"/> { props.t('See Details') }
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>

            <PaymentInfo block={block} show={showPayemnt} toggle={toggleShowPayment} />

        </Modal>
    )
}

export default withRouter(withNamespaces()(BlockDetails));
